<template>
  <base-spinner v-if="isLoading"></base-spinner>
  <div class="wrapper" v-else-if="!isLoading">
    <h2 class="primary">Register and Add Classes</h2>
    <hr />

    <div class="dog-top-actions">
      <el-tooltip effect="dark" content="Add Class" placement="top">
        <font-awesome-icon
          :icon="['fa', 'plus-circle']"
          class="addClassesIcon"
          @Click="handleAddClasses"
        ></font-awesome-icon>
      </el-tooltip>
      <el-tooltip effect="dark" content="Edit Trial" placement="top">
        <font-awesome-icon
          :icon="['fa', 'edit']"
          class="addClassesIcon"
          @Click="handleEditTrial"
        ></font-awesome-icon>
      </el-tooltip>
    </div>

    <TrialDetail :trial="getTrial" />
  </div>
  <el-drawer
    title="Add Class"
    v-model="drawerOpen"
    :size="350"
    :with-header="false"
    custom-class="add-classes-drawer"
  >
    <AddTrialClass
      :trialId="trialId"
      :trialStartDate="trialStartDate"
      :trialEndDate="trialEndDate"
      :trialSanctionBody="trialSanctionBody"
      :upcomingOrPast="upcomingOrPast"
    />
  </el-drawer>
</template>

<script>
import AddTrialClass from "./AddTrialClass.vue";
import TrialDetail from "./TrialDetail.vue";

export default {
  props: { trialId: String },
  components: { AddTrialClass, TrialDetail },
  data() {
    return {
      isLoading: true,
      drawerOpen: false,
      trialStartDate: Date.now(),
      trialEndDate: Date.now(),
      trialSanctionBody: undefined,
      upcomingOrPast: undefined,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["user/getUserId"];
    },
    getTrial() {
      const upcomingTrials = this.$store.getters["trial/ownerUpcomingTrials"];
      const pastTrials = this.$store.getters["trial/ownerPastTrials"];

      const existsInUpcomingTrials =
        upcomingTrials &&
        upcomingTrials.find((trial) => trial._id === this.trialId);

      const existsInPastTrials =
        pastTrials && pastTrials.find((trial) => trial._id === this.trialId);

      return existsInUpcomingTrials
        ? existsInUpcomingTrials
        : existsInPastTrials;
    },
  },
  watch: {
    isAuthenticated() {
      this.loadData();
    },
    getTrial(trialInfo) {
      new Date(trialInfo.startDate) >= new Date()
        ? (this.upcomingOrPast = "upcoming")
        : (this.upcomingOrPast = "past");
      this.trialStartDate = trialInfo.startDate;
      this.trialEndDate = trialInfo.endDate;
      this.trialSanctionBody = trialInfo.sanctionBody;
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("trial/loadSingleTrial", this.trialId);
        await this.$store.dispatch("trial/loadTrialClasses", this.getTrial);
      } catch (error) {
        console.error(error);
        this.$message({
          showClose: true,
          message: "Error RAC1000 loading trial data",
          type: "error",
        });
      }
      this.isLoading = false;
    },
    handleAddClasses() {
      this.drawerOpen = true;
    },
    async handleEditTrial() {
      this.isLoading = true;
      await this.$store.dispatch("trial/loadTrialToEdit", this.trialId);

      await this.$router.push({
        name: "addtrial",
        params: { addOrEdit: "edit", trialId: this.trialId },
      });

      this.isLoading = false;
    },
  },
  created() {
    this.isAuthenticated && this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

hr {
  opacity: 0.35;
}
</style>
