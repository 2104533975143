<template>
  <div v-if="isLoading"><base-spinner /></div>
  <div v-else class="form-wrapper">
    <el-form
      ref="addClassForm"
      :model="addClassForm"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="Class Date" prop="classDate">
        <el-date-picker
          v-model="addClassForm.classDate"
          type="date"
          placeholder="Class Date"
          format="MM/DD/YYYY"
          style="width: 90%"
          @change="dateChanged(addClassForm.classDate)"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="Class" prop="trialClass">
        <el-select
          v-model="addClassForm.trialClass"
          filterable
          placeholder="Class"
          style="width: 100%"
        >
          <el-option
            v-for="trialClass in trialClasses"
            :key="trialClass.name"
            :label="trialClass.label"
            :value="trialClass.label"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Level" prop="level">
        <el-select
          v-model="addClassForm.level"
          filterable
          placeholder="Level"
          style="width: 100%"
        >
          <el-option
            v-for="level in trialLevels"
            :key="level"
            :label="level"
            :value="level"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Judge" prop="judge">
        <el-input
          v-model="addClassForm.judge"
          :clearable="true"
          placeholder="Judge"
        ></el-input>
      </el-form-item>

      <div class="buttons">
        <base-button color="tertiary" @click.prevent="handleCancel"
          >Clear</base-button
        >
        <base-button color="secondary" @click.prevent="submitForm"
          >Add Class</base-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  AKCClassInfo,
  CPEClassInfo,
  USDAAClassInfo,
  UKIClassInfo,
  defaultClassInfo,
} from "@/utils/generalFormData";
import { TrialClass } from "@/models/trialClassModel";
import moment from "moment";

export default {
  props: {
    trialId: String,
    trialStartDate: String,
    trialEndDate: String,
    trialSanctionBody: String,
    upcomingOrPast: String,
  },
  data() {
    return {
      isLoading: false,
      trialClasses: undefined,
      trialLevels: undefined,
      addClassForm: {
        classDate: new Date(this.trialStartDate),
        trialClass: "",
        level: "",
        judge: "",
        trialId: this.trialId,
      },
      formRules: {
        classDate: {
          required: true,
          message: "Please select date",
          trigger: "blur",
        },
        trialClass: {
          required: true,
          message: "Please select class",
          trigger: "blur",
        },
        level: {
          required: true,
          message: "Please select level",
          trigger: "blur",
        },
      },
      AKCClassInfo: AKCClassInfo,
      CPEClassInfo: CPEClassInfo,
      USDAAClassInfo: USDAAClassInfo,
      UKIClassInfo: UKIClassInfo,
      defaultClassInfo: defaultClassInfo,
    };
  },
  methods: {
    handleCancel() {
      this.$refs["addClassForm"].resetFields();
    },
    dateChanged() {
      if (
        new Date(this.addClassForm.classDate) < new Date(this.trialStartDate) ||
        new Date(this.addClassForm.classDate) > new Date(this.trialEndDate)
      ) {
        this.$message({
          showClose: true,
          message: `Date selected is outside of trial dates. Please select a date between ${this.formatDate(
            this.trialStartDate
          )} and ${this.formatDate(this.trialEndDate)}`,
          type: "error",
          duration: 5000,
        });
        this.addClassForm.classDate = this.trialStartDate;
      }
    },
    formatDate(dateToFormat) {
      return moment(dateToFormat).format("MM/DD/YYYY");
    },
    submitForm() {
      this.isLoading = true;
      this.$refs["addClassForm"].validate(async (valid) => {
        if (valid) {
          try {
            await this.$store.dispatch("trial/addTrialClass", {
              trialClass: new TrialClass(this.addClassForm),
              upcomingOrPast: this.upcomingOrPast,
            });

            this.$message({
              showClose: true,
              message: "Trial Class Added",
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error ACF1000 creating class",
              type: "error",
              duration: 5000,
            });
          }
        } else {
          console.log("error submitting!!");
        }

        this.isLoading = false;
      });
    },
  },
  created() {
    switch (this.trialSanctionBody) {
      case "AKC":
        this.trialClasses = AKCClassInfo.trialClasses;
        this.trialLevels = AKCClassInfo.trialLevels;
        break;
      case "CPE":
        this.trialClasses = CPEClassInfo.trialClasses;
        this.trialLevels = CPEClassInfo.trialLevels;
        break;
      case "USDAA":
        this.trialClasses = USDAAClassInfo.trialClasses;
        this.trialLevels = USDAAClassInfo.trialLevels;
        break;
      case "UKI":
        this.trialClasses = UKIClassInfo.trialClasses;
        this.trialLevels = UKIClassInfo.trialLevels;
        break;
      default:
        this.trialClasses = defaultClassInfo.trialClasses;
        this.trialLevels = defaultClassInfo.trialLevels;
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}
</style>
