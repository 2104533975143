<template>
  <TrialHeader :trial="trial" />

  <section class="class-columns">
    <div
      v-for="trialClassesDate in trial.trialClassesByDate"
      :key="trialClassesDate.classDate"
      class="trial-class-column"
    >
      <div class="column-date">
        <p>
          {{ formatTrialClassDate(trialClassesDate.classDate) }}
        </p>
      </div>
      <div v-if="trialClassesDate.trialClasses.length > 0">
        <TrialClassDetail
          v-for="trialClass in trialClassesDate.trialClasses"
          :key="trialClass._id"
          :trialClass="trialClass"
          :registeredDogs="getRegisteredDogs"
          trialOrDogView="trial"
          :upcomingOrPast="getUpcomingOrPast"
          :trial="trial"
        />
      </div>
      <div v-else>
        <p>No Classes for this date</p>
      </div>
    </div>
  </section>
</template>

<script>
import TrialHeader from "./TrialHeader.vue";
import TrialClassDetail from "./TrialClassDetail.vue";

export default {
  props: { trial: Object },
  components: { TrialHeader, TrialClassDetail },

  computed: {
    getRegisteredDogs() {
      const ownerDogs = this.$store.getters["user/getUserProfile"].dogs;
      let registeredDogs = [];

      this.trial &&
        ownerDogs &&
        ownerDogs.map((dog) => {
          const isRegistered = this.trial.trialRegistrations.some(
            (regId) => regId === dog._id
          );
          return isRegistered && registeredDogs.push(dog);
        });

      return registeredDogs;
    },
    getUpcomingOrPast() {
      return new Date(this.trial.startDate) >= new Date() ? "upcoming" : "past";
    },
    formatTrialDates() {
      return `${this.trial.formatMonthAndDay(
        this.trial.startDate
      )} - ${this.trial.formatMonthAndDay(this.trial.endDate)}`;
    },
  },
  methods: {
    formatTrialClassDate(dateToFormat) {
      return this.trial.formatMonthAndDay(dateToFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
.class-columns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 30px;
}

.column-date {
  margin-bottom: 15px;
  border-bottom: 1px solid $tertiary-15;

  p {
    margin-bottom: 5px;
  }
}

.trial-class-column {
  width: 360px;
  min-height: 100px;
  margin-right: 50px;
}
</style>
